







































































import {Component, Vue, Prop, VModel} from 'vue-property-decorator';

interface Header {
  text: string;
  value: string;
  width: string;
  class: string;
}

/**
 * Simple component that lists all items in a table
 */
@Component({
  components: {
    PaginationComponent: () => import(
        '@/components/shared/table/Pagination.component.vue'),
  },
})
export default class TableComponent extends Vue {
  /**
   * The string to search with in the table items
   */
  @VModel({default: '', type: String})
  public searchString!: string;

  @Prop({default: ''})
  public height!: string;

  /**
   * The important keys of the items to search in, using the searchString. If it is undefined, every key is important.
   * Example: {
   *    name: String,
   *    address: Address, This causes to get through all Address Keys
   *    contactPerson: {
   *      firstName: String,
   *      lastName: String,
   *    },
   * }
   * The type does not matter
   */
  @Prop({default: () => undefined, type: Object})
  public importantKeys!: any;
  /**
   * The headers of the table
   */
  @Prop({default: () => []})
  public headers!: Header[];
  /**
   * The items that should be displayed in the table
   */
  @Prop({default: () => []})
  public items!: any[];

  @Prop({
    default: () => () => {
      return;
    },
  })
  public onRowClick: any;

  @Prop({
    default: () => () => {
      return;
    },
  })
  public sorter: any;

  private contentHeight: string;

  private sortedDesc: boolean = true;

  constructor() {
    super();
    let heightForCalc = this.height;
    // If the first 5 letters are 'calc(' get the content of the calc function and put it in another one, to calculate
    // the height of the table content. Adjusting height with classes and percentages didn't work that good
    if (this.height.trim().substr(0, 5) === 'calc(') {
      heightForCalc = this.height.slice(5, this.height.length - 1);
    }
    this.contentHeight = `calc(${heightForCalc} - 68px - 74px)`;
  }

  /**
   * The value how many items should be displayed per page
   * @private
   */
  private perPage: number = 1;
  /**
   * The current page
   * @private
   */
  private page: number = 1;

  private get filteredItems() {
    let tableItems = this.items;
    if (this.searchString.trim() !== '') {
      tableItems = this.searchInItems();
    }
    return tableItems;
  }

  private get tableItems(): any[] {
    return this.filteredItems.filter((item: any, index: number) => index >= (this.page - 1) * this.perPage && index < (this.page) * this.perPage);
  }

  private isArray = (item: any) => {
    return Array.isArray(item);
  }

  private changePerPage(count: number) {
    this.perPage = count;
  }

  private searchInItems() {
    // split at space?
    const searchValue = this.searchString.toLowerCase();
    return this.items.filter((item: any) => this.searchInKeys(item, searchValue, this.importantKeys));
  }

  private searchInKeys(item: any, searchValue: string, importantKey?: any): boolean {
    for (const key of Object.keys(item)) {
      if (importantKey === undefined || importantKey[key]) {
        if (typeof item[key] === 'object') {
          if (this.searchInKeys(item[key], searchValue, importantKey ? importantKey[key] : undefined)) {
            return true;
          }
        } else {
          if (item[key].toString().toLowerCase().includes(searchValue)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  private toggleSortedDesc() {
    this.sortedDesc = !this.sortedDesc;
  }
}
